import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHttpClient } from '../hooks/http-hook';
import LoadingSpinner from './LoadingSpinner';
import StopTimesList from './StopTimesList';

const StopTimes = props => {
    const { line_name, direction, sort, date } = useParams();
    const timestamp = Date.now();
    const d1 = new Date(timestamp).toLocaleDateString("hu-HU");
    const d2 = d1.replace(/. /g,'-');
    const now = d2.replace('.','');
    const [ date2, setDateOrNow ] = useState(date);
    if(!date2) setDateOrNow(now);
    const [loadedStopTimes, setLoadedStopTimes] = useState();
    const { isLoading, sendRequest } = useHttpClient();

    useEffect(() => {
        const fetchRequest = async () => {
            const baseUrl = `https://www.menetrend-debrecen.hu/menetrend_backend`;
            const options = {
                url:  `${baseUrl}/stop-times/${line_name}/${direction}/${sort}/${date2}`,
                method: 'GET',
                body: null,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            try {
            const responseData = await sendRequest(
                options.url,
                options.method,
                options.body,
                options.headers
            );
            setLoadedStopTimes(responseData.data);
            } catch (err) {}
        };
        fetchRequest();
        }, [sendRequest,line_name,direction,sort,date2]);
        

        //props && console.log(props.sharedNyomvid);
        
    return (
        <React.Fragment>
            {isLoading && (
                <div className="center">
                    <LoadingSpinner asOverlay />
                </div>
            )}
            {loadedStopTimes && (
                <>
              <StopTimesList items={loadedStopTimes.stopTimes} sharedClass={props && props.sharedClass} sharedNyomvid={props && props.sharedNyomvid}/>
              </>
            )}
        </React.Fragment>
      );

   };
   
   export default StopTimes;