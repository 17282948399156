import React, { useState, useEffect } from 'react';
import LineCard from './LineCard';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import './LineList.css';
import ScrollToTop from '../components/ScrollToTop';

const LineList = (props)=> {
    const { line_name, date } = useParams();
    const timestamp = Date.now();
    const d1 = new Date(timestamp).toLocaleDateString("hu-HU");
    const d2 = d1.replace(/. /g,'-');
    const now = d2.replace('.','');
    const [ date2, setDateOrNow ] = useState(date);
    if(!date2) setDateOrNow(now);

    const { onClose } = props;
    if (props.length === 0) {
        return (
            <div>Nincs adat.</div>
        );
    }

    const handleItemClose = () => {
        onClose();
      };

    return (
        <div>
            <div className='sep-box'>
                <div className="sep-icon tram-icon"></div>
                <div className="separator">Villamos vonalak</div>
            </div>
            <div className="container">
                {props.items.filter(item => item.f_teljtip === 'VI').map(trams => (
                    <div key={trams.name} className="item tram" onClick={() => line_name === undefined ? null : handleItemClose()}>
                        <Link to={`${props.content === '1' ? '/live' : ''}/${trams.f_vonal_group}/o/1/${props.content === '1' ? '1' : '0'}/${date2}`} title={`Villamos: ${trams.f_utvonal}`}>
                            <LineCard key={trams.name} route_id={trams.name}/>
                        </Link>
                    </div>
                ))}
            </div>
            <div className='sep-box'>
                <div className="sep-icon sep-trolley trolleybus-icon"></div>
                <div className="separator sep-trolley">Trolibusz vonalak</div>
            </div>
            <div className="container">
                {props.items.filter(item => item.f_teljtip === 'TR').map(trolleys => (
                    <div key={trolleys.name} className="item trolley" onClick={() => line_name === undefined ? null : handleItemClose()}>
                        <Link key={trolleys.name} to={`${props.content === '1' ? '/live' : ''}/${trolleys.f_vonal_group}/o/1/${props.content === '1' ? '1' : '0'}/${date2}`} title={`Trollibusz: ${trolleys.f_utvonal}`}>
                            <LineCard key={trolleys.name} route_id={trolleys.name}/>
                        </Link>
                    </div>
                ))}
            </div>
            <div className='sep-box'>
                <div className="sep-icon sep-bus bus-icon"></div>
                <div className="separator sep-bus">Autóbusz vonalak</div>
            </div>
            <div className="container">
                {props.items.filter(item => item.f_teljtip === 'AU').filter(item => item.night !== 1).filter(item => item.shopping !== 1).filter(item => item.airport !== 1).map(buses => (
                    <div key={buses.name} className={buses.name === '1V-2V' ? "item repl" : "item bus"} onClick={() => line_name === undefined ? null : handleItemClose()}>
                        <Link key={buses.name} to={`${props.content === '1' ? '/live' : ''}/${buses.f_vonal_group}/${(buses.f_vonal_group === '60h' || buses.f_vonal_group === '36e') ? 'v' : 'o'}/1/${props.content === '1' ? '1' : '0'}/${date2}`} title={`Autóbusz: ${buses.f_utvonal}`}>
                            <LineCard key={buses.name} route_id={buses.name}/>
                        </Link>
                    </div>
                ))}
            </div>
            <div className='sep-box'>
                <div className="sep-icon sep-nightbus nightbus-icon"></div>
                <div className="separator sep-nightbus">Éjszakai autóbusz vonalak</div>
            </div>
            <div className="container">
                {props.items.filter(item => item.f_teljtip === 'AU').filter(item => item.night === 1).map(buses => (
                    <div key={buses.name} className="item nightbus" onClick={() => line_name === undefined ? null : handleItemClose()}>
                        <Link key={buses.name} to={`${props.content === '1' ? '/live' : ''}/${buses.f_vonal_group}/o/1/${props.content === '1' ? '1' : '0'}/${date2}`} title={`Éjszakai autóbusz: ${buses.f_utvonal}`}>
                            <LineCard key={buses.name} route_id={buses.name}/>
                        </Link>
                    </div>
                ))}
            </div>
            <div className='sep-box'>
                <div className="sep-icon sep-airport airport-icon"></div>
                <div className="separator sep-airport">Reptéri autóbusz vonalak</div>
            </div>
            <div className="container">
                {props.items.filter(item => item.f_teljtip === 'AU').filter(item => item.airport === 1).map(buses => (
                    <div key={buses.name} className="item airport" onClick={() => line_name === undefined ? null : handleItemClose()}>
                        <Link key={buses.name} to={`${props.content === '1' ? '/live' : ''}/${buses.f_vonal_group}/o/1/${props.content === '1' ? '1' : '0'}/${date2}`} title={`Reptéri autóbusz: ${buses.f_utvonal}`}>
                            <LineCard key={buses.name} route_id={buses.name}/>
                        </Link>
                    </div>
                ))}
            </div>
            <div className='sep-box'>
                <div className="sep-icon sep-shopping shopping-icon"></div>
                <div className="separator sep-shopping">Áruházi autóbusz vonalak</div>
            </div>
            <div className="container">
                {props.items.filter(item => item.f_teljtip === 'AU').filter(item => item.shopping === 1).map(buses => (
                    <div key={buses.name} className="item shopping" onClick={() => line_name === undefined ? null : handleItemClose()}>
                        <Link key={buses.name} to={`${props.content === '1' ? '/live' : ''}/${buses.f_vonal_group}/o/1/${props.content === '1' ? '1' : '0'}/${date2}`} title={`Áruházi autóbusz: ${buses.f_utvonal}`}>
                            <LineCard key={buses.name} route_id={buses.name}/>
                            <ScrollToTop />
                        </Link>
                    </div>
                ))}
            </div>


        </div>
    );

};

export default LineList;