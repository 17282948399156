import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHttpClient } from '../hooks/http-hook';
import LoadingSpinner from './LoadingSpinner';
import SignList from './SignList';

const TimeDescription = () => {
    const { line_name } = useParams();
    const { direction } = useParams();
    const { sort } = useParams();
    const timestamp = Date.now();
    const d1 = new Date(timestamp).toLocaleDateString("hu-HU");
    const d2 = d1.replace(/. /g,'-');
    const now = d2.replace('.','');
    const { date_or_now } = useParams();
    const [ date, setDateOrNow ] = useState(date_or_now);
    if(!date) setDateOrNow(now);
    const [loadedSigns, setLoadedSigns] = useState();
    const { isLoading, sendRequest } = useHttpClient();

    useEffect(() => {
        const fetchRequest = async () => {
            const baseUrl = `https://www.menetrend-debrecen.hu/menetrend_backend`;
            setLoadedSigns();
            const options = {
                url:  `${baseUrl}/signs/${line_name}/${direction}/${sort}/${date}`,
                method: 'GET',
                body: null,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            try {
            const responseData = await sendRequest(
                options.url,
                options.method,
                options.body,
                options.headers
            );
            setLoadedSigns(responseData.data);
            } catch (err) {}
        };
        fetchRequest();
        }, [sendRequest,line_name,direction,sort,date]);

        return (
        <React.Fragment>
            {isLoading && (
                <div className="center">
                    <LoadingSpinner asOverlay />
                </div>
            )}
            {loadedSigns && (
                <div style={{lineHeight:'22px'}}className="center">
                    <SignList loadedSigns={loadedSigns} />
                </div>
            )}
        </React.Fragment>
      );

   };
   
   export default TimeDescription;