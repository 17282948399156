
import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../hooks/http-hook';
import LoadingSpinner from '../components/LoadingSpinner';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LineList from '../components/LineList';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import './LineDialog.css';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

const LineDialog = ({ setGroup, switchButton, handleSwitchButton, ...props }) => {

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    const labelStyle = {
      fontWeight:'bold',
      marginLeft:'10px'
    }

    const buttonStyle = {
      display:'flex',
      backgroundColor:'#ffd200',
      color:'#000',
      fontWeight:'bold',
      border:'none',
      borderRadius:'20px',
      fontSize:'16px',
      marginRight:'20px',
      "&:hover": {
        background: "#ffe930",
        border:'none'
      },
      "@media (max-width: 600px)": {
        fontSize:'14px',
        lineHeight:'20px',
        padding:'10px 15px'
      }
    }

    const [loadedLines, setLoadedLines] = useState();
    const { isLoading, sendRequest } = useHttpClient();

    useEffect(() => {
        const fetchRequest = async () => {
            const baseUrl = `https://www.menetrend-debrecen.hu/menetrend_backend`;
            const options = {
                url:  `${baseUrl}/lines/${props.group}`,
                method: 'GET',
                body: null,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            try {
            const responseData = await sendRequest(
                options.url,
                options.method,
                options.body,
                options.headers
            );
            setLoadedLines(responseData.data);
            } catch (err) {}
        };
        fetchRequest();
        }, [sendRequest,props.group]);

    return (
      <React.Fragment>

        <Button variant="outlined" onClick={handleClickOpen} sx={buttonStyle}>Másik vonal</Button>
        <Dialog 
          open={open}
          onClose={handleClose}
          maxWidth='lg'
        >
          <DialogActions>
          
            <div className='dialog-action' style={{justifyContent: 'right'}}>
              {props.content === '0' && 
                <FormGroup>
                  <FormControlLabel 
                    className='form-label' 
                    control={<Switch checked={switchButton} onChange={handleSwitchButton} className='switch-button' />} 
                    label={<Typography sx={labelStyle}>VONALCSOPORTOK</Typography>} 
                  />
                </FormGroup>
              }
                <Button onClick={handleClose} autoFocus title='Bezárás'>
                  <CancelOutlinedIcon className='close-button' />
                </Button>
            </div>
          </DialogActions>
          <DialogContent>
          {isLoading && (
                  <div className="center">
                      <LoadingSpinner asOverlay />
                  </div>
              )}
              {!isLoading && loadedLines && (
                <LineList items={loadedLines.lines} onClose={handleClose} content={props.content}/>
              )}
          </DialogContent>

        </Dialog>
      </React.Fragment>
      );

}

export default LineDialog;