import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useHttpClient } from '../hooks/http-hook';
import Spinner from '../components/Spinner';
import TimeTable from '../components/TimeTable';
import LoadingSpinner from '../components/LoadingSpinner';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import 'dayjs/locale/hu';
import { huHU } from '@mui/x-date-pickers/locales';
import moment from 'moment'; //date format

const LiveTimes = () => {
    const { line_name, direction, sort, date } = useParams();
    const [loadedMainData, setLoadedMainData] = useState();
    const { isLoading, sendRequest } = useHttpClient();
    const d = new Date();
    let now = d.getHours();

    const [hour, setValue] = useState(now);
    const handleHourChange = (newValue) => {
        setValue(newValue);
    };
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();
    const [dateSet, setDate] = useState(date);

    const handleDateChange = (newDate) => {
        var format =  "YYYY-MM-DD";
        const formattedDate = moment(new Date(newDate)).format(format);
        setDate(formattedDate);
        const today = moment();
        const selectedDate = moment(formattedDate);
        if (selectedDate.diff(today, 'days') > 6) {
            setOpenDialog(true); // Ha igen, megnyitjuk a figyelmeztető párbeszédpanelt
        }
        navigate(`/live/${line_name}/${direction}/${sort}/1/${formattedDate}`);
    };
    
    // Dialog bezárásának kezelése
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    useEffect(() => {
        const fetchRequest = async () => {
            const baseUrl = `https://www.menetrend-debrecen.hu/menetrend_backend`;
            const options = {
                url:  `${baseUrl}/live-stop-times/${line_name}/${direction}/${sort}/${dateSet}/${String(hour).padStart(2,'0')}`,
                method: 'GET',
                body: null,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            try {
            const responseData = await sendRequest(
                options.url,
                options.method,
                options.body,
                options.headers
            );
            setLoadedMainData(responseData);
            } catch (err) {}
        };
        fetchRequest();
    }, [sendRequest,line_name,direction,sort,dateSet,hour]);

    return (
        <React.Fragment>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='hu' localeText={huHU.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DatePicker label="Dátum módosítása" onChange={handleDateChange} sx={{width:'100%'}} />
            </LocalizationProvider>
            {isLoading && (
                  <div className="center">
                      <LoadingSpinner asOverlay />
                  </div>
              )}
            {!isLoading && loadedMainData && 
                (<Spinner onHourChange={handleHourChange} hour={hour} items={loadedMainData}/>)}
            {!isLoading && loadedMainData && 
                (<TimeTable items={loadedMainData} hour={hour} />)
            }

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{"Figyelmeztetés"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Tájékoztatjuk, hogy az Ön által kiválasztott időpontig a menetrend módosulhat.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        Értem
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );

};

export default LiveTimes;