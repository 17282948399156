import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import Lines from './pages/Lines';
import LineDetails from './pages/LineDetails';
import LiveLineDetails from './pages/LiveLineDetails';

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Lines />} />
          <Route path="/:line_name/:direction/:sort/:live/:date" element={<LineDetails />} />
          <Route path="/live/:line_name/:direction/:sort/:live/:date" element={<LiveLineDetails />} />
          <Route path="*" element={<Lines />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
